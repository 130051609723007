import React, { FC } from 'react'

import { LocaleSelector } from '@juullabs/react-components'
import { viewlioConfig } from '@viewlio/config/src/viewlioConfig'
import { FlattenedEntry, Footer as FooterType } from '@viewlio/types/src/contentful'
import cx from 'classnames'
import cookie from 'js-cookie'

import { suppressSegment } from 'lib/juulio/api'
import { useGlobalStore } from 'stores'
import { useFromOauthCookie } from 'utils/hooks'

import styles from './Footer.module.scss'
import { getProps } from './Footer.serializer'

type Props = {
  contentfulEntry: FlattenedEntry<FooterType>
  showLocaleSelectorOnDesktop?: boolean
}

// Cookie should not be stored more that a year per GDPR guidelines.
export const ONE_YEAR = 365

export const Footer: FC<Props> = ({
  contentfulEntry,
  showLocaleSelectorOnDesktop = false,
}) => {
  const {
    locale,
    juulioStore: {
      availableLocales,
      flagUrl,
      preferences: {
        showBottomNav,
      },
    },
    shopper: {
      signedIn,
      user,
    },
  } = useGlobalStore()

  const { isFromOauth } = useFromOauthCookie()

  if (isFromOauth) {
    return null
  }

  const { leftColumns, rightHandSideContent } = getProps(contentfulEntry)

  const maybeSuppressSegment = async (e) => {
    if (e.target?.href === 'https://support.juul.com/s/ccpa') {
      e.preventDefault()
      e.stopPropagation()

      if (signedIn) {
        if (!user.isSegmentSuppressed) {
          await suppressSegment()
        }
      } else {
        // set cookie for unsgined user. It would run segment
        // suppression on sign in.
        cookie.set(viewlioConfig.cookies.doNotTrackSegment, 'true', { expires: ONE_YEAR })
      }

      location.assign(e.target.href)
    }
  }

  return (
    <div
      className={cx(styles.container, {
        [styles.withBottomNav]: showBottomNav,
      })}
      data-testid='main-footer'
    >
      <div
        className={styles.content}
        onClickCapture={e => maybeSuppressSegment(e)}
      >
        {leftColumns?.length > 0 && (
          <div className={cx(styles.column, styles.leftColumn)}>
            {leftColumns.map((column, index) => (
              <div
                key={`footer-column-${index}`}
                dangerouslySetInnerHTML={{ __html: column }}
              />
            ))}
          </div>
        )}

        <div
          className={cx(styles.column, styles.rightColumn)}
          dangerouslySetInnerHTML={{ __html: rightHandSideContent }}
        />
      </div>

      {availableLocales.length > 1 && (
        <LocaleSelector
          current={locale}
          currentBase={locale?.split('-')[0]}
          currentImage={flagUrl}
          display={true}
          locales={availableLocales}
          className={cx('content-wrapper store-locale-selector--footer', {
            'show-on-desktop': showLocaleSelectorOnDesktop,
          })}
          placement='footer'
        />
      )}
    </div>
  )
}
