import React from 'react'

import { HeaderSecondary } from '@juullabs/react-components'
import { NavigationEntry } from '@viewlio/types/src/contentful'

import { Subheader } from 'components/common/Subheader'

import { LanguageSelector } from '../LanguageSelector'
import { Logo } from '../Logo'

type Props = {
  navigationEntry: NavigationEntry
}

export const HeaderMinimal: React.FC<Props> = ({ navigationEntry }) => (
  <div data-testid='header-minimal'>
    <HeaderSecondary
      middleSlot={<Logo navigationEntry={navigationEntry}/>}
      endSlot={<LanguageSelector />}
    />
    <Subheader />
  </div>
)
