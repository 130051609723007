import React from 'react'

import { DeploymentConfig } from '@viewlio/types/src'
import { GetStaticProps } from 'next'

import { NicotineWarning } from 'components/common/NicotineWarning'
import { Footer } from 'components/contentful/Footer'
import { Landing, SignIn } from 'components/signIn/components'
import { getEntryCached } from 'lib/contentful/client'
import { getCommonStaticProps } from 'utils/dataFetching/getCommonStaticProps'
import { useFromOauthCookie } from 'utils/hooks'

const SignInPage = ({
  layoutContentfulEntries,
  contentfulEntry,
  backdropImageSectionEntry,
}) => {
  const { isFromOauth } = useFromOauthCookie()

  return (
    <div>
      <NicotineWarning
        contentfulEntry={layoutContentfulEntries.nicotineWarningEntry}
      />
      <SignIn
        backdropImageSectionEntry={backdropImageSectionEntry}
        navigationEntry={layoutContentfulEntries.navigationEntry}
        contentfulEntry={contentfulEntry}
      />
      {contentfulEntry && !isFromOauth && (
        <Landing contentfulEntry={contentfulEntry} />
      )}
      <Footer
        contentfulEntry={layoutContentfulEntries.footerEntry}
        showLocaleSelectorOnDesktop={true}
      />
    </div>
  )
}

export const deploymentConfig: DeploymentConfig = {
  attributes: ['account'],
  environments: {
    '*': {
      'juul-ca': true,
      'juul-it': true,
      'juul-uk': true,
      'juul-us': true,
    },
  },
}

export const getStaticProps: GetStaticProps = async ({
  locale,
}) => getCommonStaticProps({
  deploymentConfig,
  locale,
  metaEntryLookup: 'signin',
}, async () => ({
  backdropImageSectionEntry: await getEntryCached('image_section', locale, {
    'fields.retrieval_key': 'signin-devices',
  }),
  contentfulEntry: await getEntryCached('marketing_page', locale, {
    'fields.slug': '/sign-in',
  }),
}))

export default SignInPage
